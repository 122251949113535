export const firebaseConfig = {
  apiKey: 'AIzaSyCp_HIuaKtM5prx8JHy8naRKrKgbMsbKcI',
  authDomain: 'mintcard-system.firebaseapp.com',
  projectId: 'mintcard-system',
  storageBucket: 'mintcard-system.appspot.com',
  messagingSenderId: '1011097446077',
  appId: '1:1011097446077:web:f281d083b0d3449e2439d4',
  measurementId: 'G-GW2Y1YE42W',
};

export const cognitoConfig = {
  userPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
  clientId: process.env.REACT_APP_AWS_COGNITO_CLIENT_ID
};

export const auth0Config = {
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN
};

export const mapConfig = process.env.REACT_APP_MAP_MAPBOX;

export const googleAnalyticsConfig = process.env.REACT_APP_GA_MEASUREMENT_ID;
