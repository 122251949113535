import PropTypes from 'prop-types';
// material
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';
import useSettings from '../hooks/useSettings';

// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object
};

export default function Logo({ sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const { themeMode } = useSettings();
  console.log(themeMode);

  return (
    <Box sx={{ width: 170, height: 50, ...sx }}>
      {themeMode === 'dark' && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          viewBox="0 0 933.11 234.94"
        >
          <defs>
            <style>{".cls-1{fill:#fff}"}</style>
            <linearGradient
              id="Degradado_sin_nombre_25"
              x1={255.29}
              y1={55.25}
              x2={18.78}
              y2={243.76}
              gradientUnits="userSpaceOnUse"
            >
              <stop offset={0} stopColor="#26449a" />
              <stop offset={0.97} stopColor="#26449a" />
            </linearGradient>
            <linearGradient
              id="Degradado_sin_nombre_23"
              x1={152.32}
              y1={18.84}
              x2={1.64}
              y2={135.69}
              gradientUnits="userSpaceOnUse"
            >
              <stop offset={0} stopColor="#33c2ff" />
              <stop offset={1} stopColor="#0080ff" />
            </linearGradient>
            <linearGradient
              id="Degradado_sin_nombre_23-2"
              x1={323.15}
              y1={37.13}
              x2={49.79}
              y2={170.16}
              xlinkHref="#Degradado_sin_nombre_23"
            />
          </defs>
          <g id="Capa_1" data-name="Capa 1">
            <path
              className="cls-1"
              d="M104.03 28.96L337.45 36.29 337.45 172.8 48.43 164.91 119.69 121.43 135.47 43.21 104.03 28.96z"
            />
            <path
              d="M271.79 209.84H49.53a24.42 24.42 0 01-24.38-24.39V41.69A35.57 35.57 0 00.14 73.3c-.05.77-.09 1.55-.09 2.33v123.8a35.55 35.55 0 0035.5 35.5h233c.79 0 1.57 0 2.34-.09a35.57 35.57 0 0031.61-25h-30.71z"
              fill="url(#Degradado_sin_nombre_25)"
            />
            <path
              d="M100.87 45H74.36a10.79 10.79 0 00-10.79 10.76v26.52a10.8 10.8 0 0010.79 10.78h26.51a10.8 10.8 0 0010.79-10.78V55.76A10.79 10.79 0 00100.87 45zm-.41 32.29a4.6 4.6 0 01-4.6 4.6H79.37a4.59 4.59 0 01-4.6-4.6V60.77a4.59 4.59 0 014.6-4.6h16.49a4.6 4.6 0 014.6 4.6z"
              fill="url(#Degradado_sin_nombre_23)"
            />
            <path
              className="cls-1"
              d="M353.67 29.83V1.58h33.4v28.25zm1.57 135.62V41.93h32.28v123.52zM496.44 82.28v83.17h-32.05v-79.8c0-12.11-4.49-16.82-12.11-16.82-6.72 0-13 5.83-16.36 15.92v80.7h-32.06V41.93h32.06v14.8c6.05-10.31 15.46-17.26 28-17.26 20.87 0 32.52 14.12 32.52 42.81zM515.54 129.81v-60.3h-11.43V54.26l10.09-12.55 20.39-26.23h13v26.45h26.67v27.58H547.6v56.93c0 8.08 2.91 12.56 9.64 12.56a39.29 39.29 0 0013.89-3.14l5.16 26.9a75.12 75.12 0 01-26.45 5.38c-22.19 0-34.3-12.55-34.3-38.33zM578.42 103.8c0-40.35 20.4-64.33 52.23-64.33a56.47 56.47 0 0127.8 7.17l-7.85 27.57c-6.72-4-11.88-5.6-17.93-5.6-13.45 0-22 11.88-22 35 0 23.31 8.52 35.19 22 35.19a35.68 35.68 0 0018.16-5.15l7.62 27.12c-9.86 5.38-18.61 7.4-27.57 7.4-32.51-.03-52.46-24.02-52.46-64.37zM752.37 87.89v77.56h-31.83v-14.57c-5.6 11.66-15.47 17.26-27.12 17.26-18.42 0-31.42-13.23-31.42-39.68 0-22.64 11.44-37.88 37.66-37.88 6.51 0 14.8.45 20.85 1.12v-4.93c0-14.13-6.28-19.73-18.83-19.73a67.82 67.82 0 00-26 5.83l-3.36-27.13a86.28 86.28 0 0133.68-6.5c32.92 0 46.37 18.16 46.37 48.65zm-31.83 24.43V111a114.56 114.56 0 00-13.22-.9c-9.64 0-14.57 5.16-14.57 15.92s4.93 15 11.88 15c9.86-.02 15.91-8.97 15.91-28.7zM831.33 41.26l-2.47 33.18a57.8 57.8 0 00-10.76-1.12c-11 0-17.48 11.43-17.48 34.07v58.06h-32.29V41.93h32.29v13c5.15-10.76 12.77-15 22-15a31.82 31.82 0 018.71 1.33zM833.52 103.36c0-42.37 15.92-63.89 40.13-63.89 10.09 0 20 3.36 27.35 13.45V0h32.06v165.45H901v-10.76c-7.4 9.86-17.49 13.45-27.8 13.45-23.54 0-39.68-22.14-39.68-64.78zM901 104.7c0-23.54-6.28-36.54-17.93-36.54-9.87 0-16.82 9.86-16.82 35.2 0 26 7 36.09 16.82 36.09 11.2 0 17.93-14.57 17.93-34.75z"
            />
            <path
              d="M224.71 52.55a32.1 32.1 0 0126.13-13c13.24 0 22.11 5.66 26.85 17.2a.23.23 0 00.42 0c6.5-10.49 16.89-17.24 29.68-17.24 15.57 0 25.23 7.66 29.1 23.35a.28.28 0 00.56-.06V23.09a16.35 16.35 0 00-16.34-16.34H49.78a16.34 16.34 0 00-16.34 16.34V185.2a16.33 16.33 0 0016.34 16.34h271.33a16.34 16.34 0 0016.34-16.34v-19.94a.25.25 0 00-.25-.25h-30.06a.24.24 0 01-.24-.24V85.29c0-12.06-4-16.52-10.5-16.52-7.14 0-12.72 5.57-14.73 16.5v79.45a.24.24 0 01-.24.24H250a.24.24 0 01-.24-.24V85.29c0-12.5-4-16.52-10.27-16.52-7.37 0-13.17 6.24-15.19 18.06v77.94a.25.25 0 01-.25.24h-31.47a.24.24 0 01-.24-.24V42.21a.24.24 0 01.24-.24H224a.25.25 0 01.25.24v10.2a.24.24 0 00.46.14zm-107 29.73a16.8 16.8 0 01-16.78 16.78H74.36a16 16 0 01-1.71-.06 16.81 16.81 0 01-15.08-16.72V55.76A16.8 16.8 0 0174.36 39h26.52a16.8 16.8 0 0116.78 16.78z"
              fill="url(#Degradado_sin_nombre_23-2)"
            />
          </g>
        </svg>
      )}
      {themeMode === 'light' && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 933.11 234.94"
        >
          <defs>
            <style>{".cls-3{fill:#313e56}"}</style>
            <linearGradient
              id="Degradado_sin_nombre_25"
              x1={255.27}
              y1={55.24}
              x2={18.72}
              y2={243.78}
              gradientUnits="userSpaceOnUse"
            >
              <stop offset={0} stopColor="#26449a" />
              <stop offset={0.97} stopColor="#26449a" />
            </linearGradient>
            <linearGradient
              id="Degradado_sin_nombre_34"
              x1={186.44}
              y1={-22.65}
              x2={-36.4}
              y2={183.95}
              gradientUnits="userSpaceOnUse"
            >
              <stop offset={0} stopColor="#3df" />
              <stop offset={0.66} stopColor="#0080ff" />
            </linearGradient>
            <linearGradient
              id="Degradado_sin_nombre_23"
              x1={310.83}
              y1={23.96}
              x2={54.8}
              y2={187.65}
              gradientUnits="userSpaceOnUse"
            >
              <stop offset={0} stopColor="#33c2ff" />
              <stop offset={1} stopColor="#0080ff" />
            </linearGradient>
          </defs>
          <g id="Capa_1" data-name="Capa 1">
            <path
              d="M271.77 209.85H49.49a24.42 24.42 0 01-24.39-24.4V41.68a35.58 35.58 0 00-25 31.61c-.1.78-.1 1.55-.1 2.34v123.81a35.55 35.55 0 0035.51 35.5h233c.79 0 1.57 0 2.34-.08a35.6 35.6 0 0031.61-25h-30.69z"
              fill="url(#Degradado_sin_nombre_25)"
            />
            <path
              d="M100.83 45H74.31a10.8 10.8 0 00-10.78 10.75v26.52a10.8 10.8 0 0010.78 10.79h26.52a10.8 10.8 0 0010.79-10.79V55.75A10.8 10.8 0 00100.83 45zm-.41 32.29a4.6 4.6 0 01-4.6 4.6h-16.5a4.6 4.6 0 01-4.6-4.6V60.76a4.6 4.6 0 014.6-4.6h16.5a4.6 4.6 0 014.6 4.6z"
              fill="url(#Degradado_sin_nombre_34)"
            />
            <path
              className="cls-3"
              d="M353.66 29.82V1.57h33.4v28.25zm1.57 135.64V41.92h32.28v123.54zM496.45 82.28v83.18h-32.06V85.64c0-12.1-4.49-16.81-12.11-16.81-6.73 0-13 5.83-16.37 15.92v80.71h-32.06V41.92h32.06v14.8c6.06-10.31 15.47-17.26 28-17.26 20.88 0 32.54 14.12 32.54 42.82zM515.55 129.81V69.5h-11.44V54.26L514.2 41.7l20.4-26.23h13v26.45h26.68V69.5h-26.67v57c0 8.07 2.91 12.55 9.64 12.55a39.39 39.39 0 0013.9-3.14l5.15 26.91a75.35 75.35 0 01-26.45 5.38c-22.2-.05-34.3-12.61-34.3-38.39zM578.43 103.8c0-40.35 20.4-64.34 52.24-64.34a56.58 56.58 0 0127.8 7.17l-7.85 27.58c-6.72-4-11.88-5.61-17.93-5.61-13.45 0-22 11.89-22 35 0 23.31 8.52 35.2 22 35.2a35.6 35.6 0 0018.16-5.16l7.62 27.13c-9.86 5.38-18.61 7.4-27.58 7.4-32.5-.02-52.46-24.01-52.46-64.37zM752.41 87.88v77.58h-31.84v-14.58c-5.6 11.66-15.47 17.27-27.13 17.27-18.38 0-31.38-13.23-31.38-39.69 0-22.64 11.43-37.89 37.66-37.89a206.71 206.71 0 0120.85 1.13v-4.94c0-14.12-6.28-19.73-18.83-19.73a67.87 67.87 0 00-26 5.83l-3.36-27.12A86.15 86.15 0 01706 39.23c33 0 46.41 18.16 46.41 48.65zm-31.84 24.44V111a114.58 114.58 0 00-13.23-.9c-9.64 0-14.57 5.16-14.57 15.92s4.93 15 11.88 15c9.87-.02 15.92-8.97 15.92-28.7zM831.37 41.25l-2.47 33.18a58.66 58.66 0 00-10.76-1.12c-11 0-17.49 11.44-17.49 34.08v58.07h-32.28V41.92h32.28v13c5.16-10.76 12.78-15 22-15a31.82 31.82 0 018.72 1.33zM833.57 103.35c0-42.37 15.91-63.89 40.13-63.89 10.09 0 19.95 3.36 27.35 13.45V0h32.06v165.46h-32.06v-10.77c-7.4 9.87-17.49 13.46-27.8 13.46-23.54 0-39.68-22.15-39.68-64.8zm67.48 1.35c0-23.54-6.28-36.54-17.94-36.54-9.86 0-16.81 9.86-16.81 35.19 0 26 7 36.1 16.81 36.1 11.21 0 17.94-14.57 17.94-34.75z"
            />
            <path
              d="M224.68 52.54a32.1 32.1 0 0126.14-13c13.23 0 22.11 5.67 26.85 17.2a.23.23 0 00.42 0c6.5-10.49 16.91-17.24 29.68-17.24 15.57 0 25.24 7.67 29.1 23.36a.28.28 0 00.56-.07V23.08a16.34 16.34 0 00-16.34-16.34H49.73a16.35 16.35 0 00-16.34 16.34v162.13a16.34 16.34 0 0016.34 16.34h271.36a16.33 16.33 0 0016.34-16.34v-19.94a.25.25 0 00-.24-.25h-30.07a.25.25 0 01-.24-.25V85.29c0-12.06-4-16.53-10.5-16.53-7.14 0-12.72 5.58-14.73 16.5v79.46a.25.25 0 01-.25.25H250a.25.25 0 01-.25-.25V85.29c0-12.51-4-16.53-10.27-16.53-7.37 0-13.17 6.25-15.18 18.07v77.9a.25.25 0 01-.24.25h-31.51a.25.25 0 01-.24-.25V42.21a.25.25 0 01.24-.25H224a.25.25 0 01.24.25V52.4a.24.24 0 00.44.14zM117.62 82.27a16.8 16.8 0 01-16.78 16.79H74.32A16.37 16.37 0 0172.6 99a16.81 16.81 0 01-15.07-16.7V55.75A16.8 16.8 0 0174.31 39h26.53a16.8 16.8 0 0116.78 16.78z"
              fill="url(#Degradado_sin_nombre_23)"
            />
          </g>
        </svg>
      )}
    </Box>
  );
}
